body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pos-middle {
  height: 100vh !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.sidebar {
  scrollbar-color: var(
      --variant-plainColor,
      var(
        --joy-palette-neutral-plainColor,
        var(--joy-palette-neutral-700, #32383e)
      )
    )
    var(--joy-palette-background-surface);
}

:not(.sidebar) {
  scrollbar-color: var(
      --variant-plainColor,
      var(
        --joy-palette-neutral-plainColor,
        var(--joy-palette-neutral-700, #32383e)
      )
    )
    var(--joy-palette-background-body);
}

.modal-image {
  border-radius: 0.5rem;
}

.help-link {
  color: var(--joy-palette-text-color);
}

.help-link:hover {
  color: var(--joy-palette-primary-400);
}

img.clickable {
  cursor: pointer;
}

.App {
  text-align: center;
}

.form-flex {
  display: flex;
  flex-direction: column;
}
